import React from "react";
import Layout from "../layout/Layout";
import { Frown, ArrowLeft } from "react-feather";
import Link from "next/link";

const Error = seo => {
  return (
    <>
      <Layout seo={seo}>
        <div className="container mx-auto px-3 items-center pt-16 flex flex-col">
          <div className="w-full lg:w-6/12 text-center text-3xl text-dulux font-bold">
            <Frown
              className="inline-block mr-2"
              width="60"
              height="60"
              strokeWidth="2"
              stroke="#001E45"
            />
          </div>
          <div className="w-full lg:w-6/12 text-center text-3xl text-dulux font-bold mt-5">
            Whoops, looks like we can't find the page you are looking for. It
            may no longer exist.
          </div>
          <div className="w-full lg:w-6/12 text-center text-lg text-dulux mt-5">
            <Link href="/">
              <a className="underline">
                <ArrowLeft
                  className="inline-block mr-2"
                  strokeWidth="2"
                  stroke="#001E45"
                />
                Return to the homepage
              </a>
            </Link>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Error;
