import get from "lodash/get";

export function getNodeKey(node, index = null) {
  return get(node, "data.target.sys.id", index);
}

export function getNodeType(node) {
  return get(node, "nodeType", "");
}

export function getNodeContentType(node) {
  return get(node, "data.target.sys.contentType.sys.id", get(node, "data.target.sys.type", "moduleRaw"));
}

export function getFieldAttr(node, attribute, defaultVal = null) {
  return get(fields(node), attribute, defaultVal);
}

export function getSysAttr(node, attribute, defaultVal = null) {
  return get(sys(node), attribute, defaultVal);
}

// Handle accessing the main node from Contentful depending on layers of module
function getNode(node) {
  return get(node, "data.data.target", get(node, "data.target", get(node, "data", node)));
}

function fields(node) {
  return get(getNode(node), 'fields');
}

function sys(node) {
  return get(getNode(node), 'sys');
}

export function items(node) {
  return get(fields(node), 'content.content', get(fields(node), 'content', []));
}



