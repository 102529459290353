import React from "react";
import Error from "../components/page/Error";

const ErrorPage = () => {
  const seo = {
    title: "Error page",
    description: "An error was encountered",
    noIndex: true,
  };

  return (
    <Error seo={seo} />
  );
};

export default ErrorPage;
