import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import { contrastingColour } from "../../lib/utilities/colour";

const Tile = props => {
  const link =
    typeof props.ctaLink === "string" && props.ctaLink.length > 0
      ? { href: props.ctaLink }
      : props.ctaLink;

  const openLinkInNewTab = () => {
    if (props.ctaNewTab) {
      return "_blank";
    }
  };

  return (
    <div className="w-6/12 md:w-3/12 pr-3 lg:pr-6 mb-3 lg:mb-6">
      {props.image && (
        <>
          {link ? (
            <Link {...link}>
              <a
                className="text-normal lg:text-lg"
                alt={props.title}
                target={openLinkInNewTab()}
              >
                <div className="relative inline-flex">
                  <div>
                    <img
                      className="w-full"
                      src={props.image}
                      alt={props.title}
                    />
                  </div>
                  {props.label && (
                    <div
                      className="absolute top-0 right-0 bg-white px-3 py-1 rounded m-1"
                      style={{
                        color: contrastingColour(props.textBackground),
                        backgroundColor: props.textBackground,
                      }}
                    >
                      {props.label}
                    </div>
                  )}
                  {props.layout === "Top" && (
                    <>
                      {(props.title || props.secondaryTitle) && (
                        <div
                          className="absolute bottom-0 left-0 bg-white px-2 md:px-5 py-2 w-11/12 lg:w-10/12 text-center"
                          style={{
                            color: contrastingColour(props.textBackground),
                            backgroundColor: props.textBackground,
                          }}
                        >
                          {props.title}
                          {props.secondaryTitle && (
                            <>
                              <br />
                              <span className="text-sm text-gray-700">
                                {props.secondaryTitle}
                              </span>
                            </>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                {props.layout === "Bottom" && (
                  <div className={props.titleCentered ? "text-center" : ""}>
                    {props.title && <div className="mt-2">{props.title}</div>}
                    {props.secondaryTitle && (
                      <div className="mt-1 text-sm text-gray-700">
                        {props.secondaryTitle}
                      </div>
                    )}
                  </div>
                )}
              </a>
            </Link>
          ) : (
            <div className="text-normal lg:text-2xl">
              <div className="relative inline-flex">
                <div>
                  <img className="w-full" src={props.image} alt={props.title} />
                </div>
                {props.label && (
                  <div
                    className="absolute top-0 right-0 bg-white px-3 py-1 rounded m-1"
                    style={{
                      color: contrastingColour(props.textBackground),
                      backgroundColor: props.textBackground,
                    }}
                  >
                    {props.label}
                  </div>
                )}
                {props.layout === "Top" && (
                  <>
                    {(props.title || props.secondaryTitle) && (
                      <div
                        className="absolute bottom-0 left-0 bg-white px-5 py-2 text-normal md:text-xl"
                        style={{
                          color: contrastingColour(props.textBackground),
                          backgroundColor: props.textBackground,
                        }}
                      >
                        {props.title}
                        {props.secondaryTitle}
                      </div>
                    )}
                  </>
                )}
              </div>
              {props.layout === "Bottom" && (
                <div className={props.titleCentered ? "text-center" : ""}>
                  {props.title && <div className="mt-2">{props.title}</div>}
                  {props.secondaryTitle && (
                    <div className="mt-1 text-sm text-gray-700">
                      {props.secondaryTitle}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

Tile.propTypes = {
  image: PropTypes.string,
  ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ctaNewTab: PropTypes.bool,
  title: PropTypes.string,
  secondaryTitle: PropTypes.string,
  textBackground: PropTypes.string,
  titleCentered: PropTypes.bool,
  label: PropTypes.string,
  layout: PropTypes.string,
};

Tile.defaultProps = {
  image: "",
  ctaLink: "",
  ctaNewTab: false,
  title: "",
  secondaryTitle: "",
  textBackground: "#FFFFFF",
  titleCentered: false,
  label: "",
  layout: "Top",
};

export default Tile;
